/* Removes outline focus on all elements */
*:focus {
    outline: none !important;
}

/* Removes autocomplete background */
input:-webkit-autofill {
    transition: background-color 60000s, color 60000s;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #777777;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

::-webkit-scrollbar-thumb:horizontal {
    background-color: transparent;
  }
